/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.custom-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  background-color: #030303;
  pointer-events: auto;
  opacity: 0.4;
}

.custom-title-toastr {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.custom-message-toastr {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;

}

.ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

.export-excel-button {
  text-align: right;
  margin-right: 30px;
}


.background-dialog-image {
  mat-dialog-container {
    background: linear-gradient( rgba(20,20,20, .94), rgba(20,20,20, .94)) !important;
  }
}

.toast-redirect-div-close-img {
  padding: .5rem;
  right: 0.5%;
  position: absolute;
}

.toast-redirect-content {
  background-color: rgb(48,150,180);
}

.toast-redirect-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(48,150,180);
  width: 23rem;
  height: 2.75rem;
  padding: 2.25rem 4rem 2.25rem 1rem;
  line-height: 1.25rem;
}

.toast-redirect {
  color: white;
}

.toast-redirect-image {
  width: 2rem;
  height: 2rem;
  margin-right: 0rem;
}

.toast-redirect-close-image {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 1%;
  color: rgb(215,235,240);
  background-color: rgb(48,150,180);
  margin-top: .25;
  cursor: pointer;
  pointer-events: auto; 
}

.toast-redirect-title {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
}

.toast-redirect-close-button {
  background-color: rgb(48,150,180);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.toast-redirect-close {
  right: 0%;
  padding: 1rem 0%
}

.toast-redirect-text {
  font-size: 13px;
}

.mat-delete-badge .mat-badge-content {
  background-color: #BD372F !important;
}
